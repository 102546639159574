






















































































































































































import Vue from 'vue'
import IAttendance from '@/models/Attendance'
import api, { routers, address } from '@/api'
import { randomBetween, notification } from '@/utils'
import { GetterTypes, ActionTypes, MutationTypes } from '@/store'

export default Vue.extend({
  data: () => ({
    loading: false,
    index: null,
    pagination: {
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
    },
    loadingMeIds: [] as Array<number>,
  }),
  components: { VuePictureSwipe: () => import('vue-picture-swipe') },
  computed: {
    list: function (): Array<IAttendance> {
      return this.$store.getters[GetterTypes.ATTENDANCES]
    },
  },
  destroyed() {
    this.clear()
  },
  mounted() {
    this.requestSolicitation()
  },
  methods: {
    getImageLink(image) {
      return `${address.baseApp}storage/${image}`
    },
    getIndex(id: number) {
      return this.list.findIndex((item) => item.id === id)
    },
    removeIdLoading(id: number) {
      this.loadingMeIds = this.loadingMeIds.filter((r) => r !== id)
    },
    clear() {
      this.$store.commit(MutationTypes.CLEAR_ATTENDANCE)
    },
    requestSolicitation() {
      setTimeout(async () => {
        try {
          this.clear()
          this.loading = true
          const { data } = await api.get(routers.Requests.CAEMA_REQUEST, {
            params: {
              page: this.pagination.currentPage,
            },
          })
          this.pagination.perPage = data.per_page
          this.pagination.totalRows = data.total
          this.$store.dispatch(ActionTypes.SET_ATTENDANCE, data.data)
        } catch {
          notification.msgBoxOk('FALHA', 'Erro ao consultar solicitações')
        } finally {
          this.loading = false
        }
      }, 100)
    },
    async requestDataAttendance(item: IAttendance) {
      if (item.phone) return false
      this.loadingMeIds.push(item.id)
      try {
        const { data } = await api.get(
          routers.Requests.CAEMA_REQUEST_ATTENDANCE,
          {
            params: {
              id: item.id,
            },
          }
        )
        this.$store.commit(MutationTypes.CHANGE_ATTENDANCE, {
          id: item.id,
          attendance: data,
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.removeIdLoading(item.id)
      }
    },
    async update(item: IAttendance, company_id: number) {
      this.loadingMeIds.push(item.id)
      try {
        await api.post(routers.Requests.CAEMA_TREATED, {
          company_id,
          id: item.id,
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.removeIdLoading(item.id)
      }
    },
    random: (min: number, max: number) => randomBetween(min, max),
  },
})
